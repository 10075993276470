import React, { useState, useRef, useEffect } from "react";
import Page from "../../components/furniture/Page";
import {
  Button,
  Icon,
  Form,
  Segment,
  Grid,
  Header,
  Input,
  Divider,
  Message,
  Container,
  Checkbox,
} from "semantic-ui-react";
import { useAuth } from "../../hooks/use-auth";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";
import config from "../../services/config";
import memberService from "../../services/MemberService";
import isEmail from "validator/lib/isEmail";
import { useRouter } from "next/router";
import Head from "next/head";
import layout from "../../services/layout";

const LinkButton = styled.button`
  color: white;
  cursor: pointer;
  background: none !important;
  border: none;
  padding: 0 !important;
`;

const Anchor = styled.a`
  color: white;
  text-decoration: underline;
  white-space: nowrap;
  &:hover,
  &:active {
    color: white;
    text-decoration: underline;
  }
`;

const CenterParagraph = styled.p`
  text-align: center;
`;

function SignUpForm(props) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const auth = useAuth();

  const handleSignUpSubmit = async (e) => {
    try {
      setIsSigningUp(true);
      setErrorMessage(null);
      const token = await props.getRecaptchaToken();
      await auth.signup(
        props.signUpState.username,
        props.signUpState.password,
        props.signUpState.name,
        token
      );
      setIsSigningUp(false);
      props.setVerifyEmailState({
        username: props.signUpState.username,
        password: props.signUpState.password,
        name: props.signUpState.name,
        issubscribed: props.signUpState.issubscribed,
        verifyingEmail: true,
      });
    } catch (ex) {
      if (ex.message.includes("already exists")) {
        setErrorMessage(
          "Looks like you already have an account with us.  Try signing in or reset your password."
        );
      } else if (
        ex.message.includes(
          "Value at 'password' failed to satisfy constraint: Member must have length"
        )
      ) {
        setErrorMessage("Password must be at least 7 characters");
      } else {
        setErrorMessage(ex.message);
      }

      setIsSigningUp(false);
    }
  };

  const handleShowToggle = async (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleCheckboxChange = (e, { checked }) => {
    props.setSignUpState({ issubscribed: checked });
  };

  const termsPageUrl = config.get("WEB_URL") + "terms";

  return (
    <>
      <Form onSubmit={handleSignUpSubmit} inverted>
        <Form.Field>
          <Input
            id="authenticate-name"
            autoComplete="name"
            name="name"
            icon="user"
            iconPosition="left"
            placeholder="Your Name"
            value={props.signUpState.name}
            onChange={(e) => props.setSignUpState({ name: e.target.value })}
          />
        </Form.Field>
        <Form.Field>
          <Input
            id="authenticate-email"
            autoComplete="email"
            name="email"
            icon="mail"
            iconPosition="left"
            placeholder="Your Email"
            value={props.signUpState.username}
            onChange={(e) => props.setSignUpState({ username: e.target.value })}
          />
        </Form.Field>
        <Form.Field>
          <Input
            autoComplete="new-password"
            icon="lock"
            iconPosition="left"
            placeholder="Password"
            value={props.signUpState.password}
            onChange={(e) => props.setSignUpState({ password: e.target.value })}
            type={showPassword ? "text" : "password"}
            action={
              <Button type="button" onClick={handleShowToggle}>
                <Icon name={showPassword ? "eye slash" : "eye"} fitted />
              </Button>
            }
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            label="Receive news and the latest cars for sale via email"
            onChange={handleCheckboxChange}
            defaultChecked
          />
        </Form.Field>
        <Button
          type="submit"
          floated="right"
          primary
          fluid
          loading={isSigningUp}
          disabled={isSigningUp}
        >
          Join
        </Button>
        <Form.Field>
          <p style={{ paddingTop: "10px", textAlign: "center" }}>
            By clicking Join, you agree to our{" "}
            <Anchor
              href={termsPageUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </Anchor>
          </p>
        </Form.Field>
      </Form>
      <Divider clearing hidden></Divider>
      {errorMessage && (
        <Message negative>
          <p>{errorMessage}</p>
        </Message>
      )}
    </>
  );
}

function SignInForm(props) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const auth = useAuth();
  const router = useRouter();

  const federatedSignIn = async (e, provider) => {
    e.preventDefault();
    const returnUrl =
      router.query.returnUrl &&
      !router.query.returnUrl.startsWith("authenticate")
        ? router.query.returnUrl
        : "/";
    await auth.federatedSignIn(provider, returnUrl);
  };

  const handleSignInSubmit = async (e) => {
    try {
      if (!isEmail(props.signInState.username)) {
        setErrorMessage({
          title: "You must login with your email address",
          body: (
            <>
              If you did login with a username and cannot remember the email
              address on your account please{" "}
              <a href="mailto:info@my105.com">contact us</a>
            </>
          ),
        });
        return;
      }

      setIsLoggingIn(true);
      setErrorMessage(null);
      const token = await props.getRecaptchaToken();
      await auth.signin(
        props.signInState.username,
        props.signInState.password,
        token
      );
      const returnUrl =
        router.query.returnUrl &&
        !router.query.returnUrl.startsWith("authenticate")
          ? router.query.returnUrl
          : "/";
      router.replace(returnUrl);
    } catch (ex) {
      setIsLoggingIn(false);
      if (ex.code === "UserNotConfirmedException") {
        await auth.resendSignUp(props.signInState.username);
        props.setVerifyEmailState({
          username: props.signInState.username,
          password: props.signInState.password,
          verifyingEmail: true,
          name: "",
          issubscribed: true,
        });
      } else if (ex.message === "UserMigration failed with error LockedOut.") {
        setErrorMessage({
          title: "Sorry, you have tried too many times",
          body: "Please try again later",
        });
      } else {
        setErrorMessage({
          title: "Your email or password seem to be incorrect",
          body: "Please check them and try again",
        });
      }
    }
  };

  const handleShowToggle = async (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Form onSubmit={handleSignInSubmit} inverted>
        <Header as="h3" inverted textAlign="center">
          Continue With
        </Header>
        <Grid columns={2} stackable>
          <Grid.Row>
            <Grid.Column>
              <Button
                onClick={(e) => federatedSignIn(e, "Facebook")}
                fluid
                icon
                labelPosition="left"
                color="facebook"
                type="button"
              >
                <Icon name="facebook f" />
                Facebook
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Button
                onClick={(e) => federatedSignIn(e, "Google")}
                fluid
                icon
                labelPosition="left"
                color="google plus"
                type="button"
              >
                <Icon name="google" />
                Google
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider horizontal inverted section>
          Or Sign In
        </Divider>
        <Form.Field>
          <Input
            id="authenticate-login-username"
            autoComplete="username"
            name="username"
            icon="mail"
            iconPosition="left"
            placeholder="Email"
            value={props.signInState.username}
            onChange={(e) => props.setSignInState({ username: e.target.value })}
          />
        </Form.Field>
        <Form.Field>
          <Input
            id="authenticate-login-password"
            name="password"
            autoComplete="current-password"
            icon="lock"
            iconPosition="left"
            placeholder="Password"
            value={props.signInState.password}
            onChange={(e) => props.setSignInState({ password: e.target.value })}
            type={showPassword ? "text" : "password"}
            action={
              <Button type="button" onClick={handleShowToggle}>
                <Icon name={showPassword ? "eye slash" : "eye"} fitted />
              </Button>
            }
          />
        </Form.Field>
        <LinkButton
          type="button"
          onClick={() =>
            props.setForgotPasswordState({
              username: props.signInState.username,
              resettingPassword: true,
            })
          }
        >
          Forgot Password?
        </LinkButton>
        <Button
          type="submit"
          floated="right"
          primary
          loading={isLoggingIn}
          disabled={isLoggingIn}
        >
          Sign In
        </Button>
      </Form>
      <Divider clearing hidden></Divider>
      {errorMessage && (
        <>
          <Message negative>
            <Message.Header>{errorMessage.title}</Message.Header>
            <p>{errorMessage.body}</p>
          </Message>
        </>
      )}
    </>
  );
}

function VerifyEmailForm(props) {
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [resendEmailVerificationMessage, setResendEmailVerificationMessage] =
    useState(null);
  const [isVerifyingEmail, setIsVerifyingEmail] = useState(false);
  const [hasSentCodeRecently, setHasSentCodeRecently] = useState(true);
  const auth = useAuth();
  const router = useRouter();

  const handleEmailVerification = async (e) => {
    try {
      setIsVerifyingEmail(true);
      setResendEmailVerificationMessage(null);
      setErrorMessage(null);
      await auth.confirmSignUp(props.verifyEmailState.username, code);
      const responseSignin = await auth.signin(
        props.verifyEmailState.username,
        props.verifyEmailState.password
      );
      try {
        await memberService.create(
          responseSignin.attributes.sub,
          props.verifyEmailState.username,
          props.verifyEmailState.issubscribed ?? true,
          responseSignin.signInUserSession.idToken.jwtToken
        );
      } catch (error) {
        console.error("failed to create member event", error);
      }
      const returnUrl =
        router.query.returnUrl &&
        !router.query.returnUrl.startsWith("authenticate")
          ? router.query.returnUrl
          : "/";
      router.replace(returnUrl);
    } catch (ex) {
      setIsVerifyingEmail(false);
      if (ex.code === "CodeMismatchException") {
        setErrorMessage({
          title: "Sorry, that code is not valid",
          body: "Please try entering it again",
        });
      } else if (ex.code === "ExpiredCodeException") {
        setErrorMessage({
          title: "Sorry, your code has expired",
          body: "Please try Resend Verification Code below",
        });
      } else {
        setErrorMessage({
          title: "Sorry, something went wrong",
          body: "Please try again, or try to Resend Verification Code below",
        });
      }
    }
  };

  const handleResendVerificationCode = async (e) => {
    e.preventDefault();
    try {
      await auth.resendSignUp(props.verifyEmailState.username);
      setErrorMessage(null);
      setHasSentCodeRecently(true);
    } catch (ex) {
      if (ex.code === "LimitExceededException") {
        setResendEmailVerificationMessage(
          "Sorry, you have requested too many codes, please try again later."
        );
      } else {
        setResendEmailVerificationMessage(
          "Failed to resend code, please try again"
        );
      }
    }
  };

  useEffect(() => {
    if (hasSentCodeRecently) {
      setTimeout(() => {
        setHasSentCodeRecently(false);
      }, 30000);
    }
  }, [hasSentCodeRecently, setHasSentCodeRecently]);

  return (
    <>
      <Divider clearing hidden></Divider>
      <LinkButton
        type="button"
        onClick={() => props.setVerifyEmailState({ verifyingEmail: false })}
      >
        &lt; Back to Join
      </LinkButton>
      <h3>We have emailed a code to {props.verifyEmailState.username}</h3>
      <p>Copy the six digit number in the email here to join</p>
      <Form onSubmit={handleEmailVerification} inverted>
        <Form.Field>
          <Input
            autoComplete="one-time-code"
            icon="key"
            iconPosition="left"
            placeholder="Code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            type="number"
          />
        </Form.Field>
        <Button
          type="submit"
          floated="right"
          primary
          fluid
          loading={isVerifyingEmail}
          disabled={isVerifyingEmail}
        >
          Verify Email
        </Button>
        {errorMessage && (
          <>
            <Divider clearing hidden></Divider>
            <Message negative>
              <Message.Header>{errorMessage.title}</Message.Header>
              <p>{errorMessage.body}</p>
            </Message>
          </>
        )}
        {!hasSentCodeRecently && (
          <>
            <Divider
              clearing
              horizontal
              inverted
              style={{ paddingTop: "50px" }}
            >
              Not received your code?
            </Divider>
            <CenterParagraph>
              Check the email address, your spam folder, or&nbsp;&nbsp;&nbsp;
              <Button
                size="tiny"
                onClick={handleResendVerificationCode}
                style={{ marginTop: "10px" }}
              >
                <Icon name="mail" />
                Resend Verification Code
              </Button>
            </CenterParagraph>
            <CenterParagraph>{resendEmailVerificationMessage}</CenterParagraph>
          </>
        )}
      </Form>
    </>
  );
}

function ForgotPasswordForm(props) {
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [checkYourEmail, setCheckYourEmail] = useState(false);
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isSendingResetCode, setIsSendingResetCode] = useState(false);
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
  const auth = useAuth();
  const router = useRouter();

  const handleSubmit = async (e) => {
    try {
      setIsSendingResetCode(true);
      const token = await props.getRecaptchaToken();
      var response = await auth.forgotPassword(
        props.forgotPasswordState.username,
        token
      );
      setIsSendingResetCode(false);
      if (
        response.payload &&
        response.payload.data &&
        response.payload.data.message
      ) {
        setErrorMessage(response.payload.data.message);
      } else if (response.CodeDeliveryDetails.DeliveryMedium === "EMAIL") {
        setCheckYourEmail(true);
      }
    } catch (ex) {
      setIsSendingResetCode(false);
      if (ex.message) {
        setErrorMessage(ex.message);
      } else {
        setSomethingWentWrong(true);
      }
    }
  };

  const handlePasswordReset = async (e) => {
    try {
      setIsUpdatingPassword(true);
      await auth.confirmPasswordReset(
        props.forgotPasswordState.username,
        code,
        newPassword
      );
      await auth.signin(props.forgotPasswordState.username, newPassword);
      setIsUpdatingPassword(false);
      const returnUrl =
        router.query.returnUrl &&
        !router.query.returnUrl.startsWith("authenticate")
          ? router.query.returnUrl
          : "/";
      router.replace(returnUrl);
    } catch (ex) {
      setIsUpdatingPassword(false);
      if (ex.message) {
        setErrorMessage(ex.message);
      } else {
        setSomethingWentWrong(true);
      }
    }
  };

  const handleShowToggle = async (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Form onSubmit={handleSubmit} inverted>
        <Header as="h3" inverted textAlign="center">
          Forgot Password
        </Header>
        <Form.Field>
          <Input
            id="authenticate-forgot-email"
            autoComplete="email"
            name="email"
            icon="mail"
            iconPosition="left"
            placeholder="Email"
            value={props.forgotPasswordState.username}
            onChange={(e) =>
              props.setForgotPasswordState({ username: e.target.value })
            }
          />
        </Form.Field>
        <LinkButton
          type="button"
          onClick={() =>
            props.setForgotPasswordState({ resettingPassword: false })
          }
        >
          Back to Sign In
        </LinkButton>
        <Button
          type="submit"
          floated="right"
          primary
          loading={isSendingResetCode}
          disabled={isSendingResetCode}
        >
          Send Reset Code
        </Button>
      </Form>
      <Divider clearing hidden></Divider>
      {checkYourEmail && (
        <>
          <Message positive>
            <Message.Header>We have emailed you a code</Message.Header>
            <p>Enter the code and a new password below</p>
          </Message>
          <CenterParagraph>
            Not received your code?
            <br />
            Check the email address, your spam folder, or try Send Reset Code
            again.
          </CenterParagraph>
          <Form onSubmit={handlePasswordReset} inverted>
            <Form.Field>
              <Input
                autoComplete="one-time-code"
                icon="key"
                iconPosition="left"
                placeholder="Code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <Input
                id="authenticate-forgot-password"
                autoComplete="new-password"
                name="password"
                icon="lock"
                iconPosition="left"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                type={showPassword ? "text" : "password"}
                action={
                  <Button type="button" onClick={handleShowToggle}>
                    <Icon name={showPassword ? "eye slash" : "eye"} fitted />
                  </Button>
                }
              />
            </Form.Field>
            <Button
              type="submit"
              floated="right"
              primary
              loading={isUpdatingPassword}
              disabled={isUpdatingPassword}
            >
              Update Password
            </Button>
          </Form>
          <Divider clearing hidden></Divider>
        </>
      )}
      {somethingWentWrong && (
        <Message negative>
          <Message.Header>Sorry, something went wrong</Message.Header>
          <p>Please check the email address and try again</p>
        </Message>
      )}
      {errorMessage && (
        <Message negative>
          <p>{errorMessage}</p>
        </Message>
      )}
    </>
  );
}

const AuthenticationGridColumn = styled(Grid.Column)`
  @media only screen and (max-width: 767px) {
    &&&&:last-child {
      margin-top: -40px !important;
    }
  }
`;

const AuthenticatePanel = styled(Container)`
  padding: 50px 0;
`;

function AuthenticatePage(props) {
  const [forgotPasswordState, setForgotPasswordState] = useState({
    username: "",
    resettingPassword: false,
  });
  const [signUpState, setSignUpState] = useState({
    name: "",
    username: "",
    password: "",
  });
  const [verifyEmailState, setVerifyEmailState] = useState({
    username: "",
    password: "",
    name: "",
    issubscribed: "",
    verifyingEmail: false,
  });
  const [signInState, setSignInState] = useState({
    username: "",
    password: "",
  });
  const recaptchaRef = useRef(null);

  const handleForgotPasswordState = (changes) => {
    setForgotPasswordState({
      ...forgotPasswordState,
      ...changes,
    });
  };
  const handleVerifyEmailState = (changes) => {
    setVerifyEmailState({
      ...verifyEmailState,
      ...changes,
    });
  };
  const handleSignUpState = (changes) => {
    setSignUpState({
      ...signUpState,
      ...changes,
    });
  };
  const handleSignInState = (changes) => {
    setSignInState({
      ...signInState,
      ...changes,
    });
  };

  const handleGetRecaptchaToken = async () => {
    if (recaptchaRef.current.getValue()) {
      await recaptchaRef.current.reset();
    }
    return await recaptchaRef.current.executeAsync();
  };

  return (
    <Page>
      <Head>
        <link rel="canonical" href={config.get("WEB_URL") + "authenticate"} />
        <title>{"My105 Login" + layout.global.titleSuffix}</title>
      </Head>
      <AuthenticatePanel>
        <Segment inverted>
          {verifyEmailState.verifyingEmail ? (
            <Container text>
              <VerifyEmailForm
                verifyEmailState={verifyEmailState}
                setVerifyEmailState={handleVerifyEmailState}
              />
              <Divider clearing hidden></Divider>
            </Container>
          ) : (
            <Grid
              columns={2}
              verticalAlign="middle"
              relaxed="very"
              padded
              stackable
              inverted
              divided
            >
              <Grid.Row>
                <AuthenticationGridColumn>
                  <Grid.Row>
                    {forgotPasswordState.resettingPassword ? (
                      <ForgotPasswordForm
                        forgotPasswordState={forgotPasswordState}
                        setForgotPasswordState={handleForgotPasswordState}
                        getRecaptchaToken={handleGetRecaptchaToken}
                      />
                    ) : (
                      <SignInForm
                        signInState={signInState}
                        setSignInState={handleSignInState}
                        setForgotPasswordState={handleForgotPasswordState}
                        setVerifyEmailState={handleVerifyEmailState}
                        getRecaptchaToken={handleGetRecaptchaToken}
                      />
                    )}
                  </Grid.Row>
                </AuthenticationGridColumn>
                <AuthenticationGridColumn>
                  <Header as="h3" inverted textAlign="center">
                    Join
                  </Header>
                  <SignUpForm
                    signUpState={signUpState}
                    setSignUpState={handleSignUpState}
                    setVerifyEmailState={handleVerifyEmailState}
                    getRecaptchaToken={handleGetRecaptchaToken}
                  />
                </AuthenticationGridColumn>
              </Grid.Row>
            </Grid>
          )}
        </Segment>
      </AuthenticatePanel>

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={config.get("RECAPTCHA_SITE_KEY")}
      />
    </Page>
  );
}

export default AuthenticatePage;
